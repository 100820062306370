<template>
  <cs-dialog
    ref="dialog"
    width="436px"
    :class="{ 'dialog-confirm': true, 'hide-cancel': model.hideCancel }"
    :submitText="model.submitText"
    :submit="submit"
  >
    <template slot="title">
      <div style="padding: 5px">
        <cs-icon name="icon-yiwen" class="text-warning" />
        <span class="fw-bold" style="margin-left: 16px; font-size: 16px">{{
          model.title
        }}</span>
      </div>
    </template>
    <div class="confirm-body">
      {{ model.message }}
    </div>
  </cs-dialog>
</template>
<script>
export default {
  props: {
    data: {},
  },
  data() {
    return {
      model: {
        title: "提示",
        message: "内容",
        submitText: "确定",
        hideCancel: false,
        ...this.data,
      },
    };
  },
  methods: {
    submit() {},
  },
};
</script>
<style lang="less">
.dialog-confirm {
  &.hide-cancel {
    .dialog-btn-cancel {
      display: none;
    }
  }
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 15px;
  }
  .ant-modal-footer {
    border-top: none;
    padding: 24px;
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .confirm-body {
    padding-left: 40px;
  }
}
</style>
